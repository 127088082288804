<template>
  <div class="foot_box">
    <div class="foot_box_cont">
      <div class="foot_box_cont_lside">
        <div>
          <p>动态</p>
          <a href="#" @click="navTo('News')">新闻中心</a>

        </div>
        <div>
          <p>关于我们</p>
          <a href="#" @click="navTo('About')">企业简介</a>
          <a href="#" @click="navTo('About')">企业文化</a>
          <a href="#" @click="navTo('About')">资质荣誉</a>
        </div>
        <div>
          <p>产品&合作</p>
          <a href="#" @click="navTo('Home')">产品中心</a>

        </div>
        <div>
          <p>联系我们</p>
          <a href="#" @click.prevent="test2()">联系我们</a>
          <a href="#" @click.prevent="test()">关于我们</a>
        </div>
      </div>
      <div class="foot_box_cont_rside">
        <div class="rside_foot_box1">
          <!-- <img alt="" src="../assets/images/code_img.png"> -->
          <!-- <p>扫码关注公众号</p> -->
        </div>
        <div class="rside_foot_box2">
          <p>全国24小时客服电话：15652347461  吴老师</p>
          <p></p>
          <p style="margin-top: 23px;">企业邮箱：</p>
          <p>{{msg}}</p>
        </div>
      </div>
    </div>
    <div style="height: 30px; text-align: center;font-size: 13px;float:left;margin-top:50px">
      <span style="color:#fff;line-height: 30px;"><a href="https://beian.miit.gov.cn" target="_blank">京ICP备2022009022号-2</a></span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: ''
    }
  },
  methods: {
    navTo (url) {
      this.$router.push({ name: url })
    },
    test () {
      this.$http.get('http://localhost:8081/api/CourseResources/CourseResourcGroup').then(res => {
        console.log(res.data.response)
        // res.data.forEach(item => {
        //   console.log(item.name)
        // })
      })
    },
    test2 () {
      this.msg = ''
    }
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
    color:#efefef;
  }
  .foot_box {
    width: 100%;
    height: 206px;
    background: url(../assets/images/foot_bg.png) no-repeat;
    background-size: 100% 100%;
  }

  .foot_box_cont {
    width: 1000px;
    margin: 0 auto;
  }

  .foot_box_cont_lside {
    float: left;
  }

  .foot_box_cont_lside div {
    float: left;
    font-family: "SimHei";
    margin: 40px 32px;
  }

  .foot_box_cont_lside div p {
    font-size: 17px;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: default;
    font-weight: 800;
  }

  .foot_box_cont_lside div a {
    display: block;
    font-size: 16px;
    color: #a8aabe;
    line-height: 25px;
    text-decoration: none;
  }

  .foot_box_cont_lside div a:HOVER {
    color: #30c2ff;
  }

  .foot_box_cont_rside {
    float: right;
    margin-top: 40px;
  }

  .rside_foot_box1 {
    float: left;
  }

  .rside_foot_box1 img {
    margin: 0px 0 0 6px;
  }

  .rside_foot_box1 p {
    width: 115px;
    font-size: 14px;
    color: #53bcff;
    margin-top: 10px;
    text-align: center;
    cursor: default;
  }

  .rside_foot_box2 {
    float: right;
    font-size: 16px;
    color: white;
    cursor: default;
  }

  .rside_foot_box2 p {
    margin: 0 0 12px 20px;
  }
</style>
