<template>
  <div id="app">
    <div id="nav">
      <div style="float:left;">
        <img alt="" src="./assets/images/project_name.jpg" style="float: left;margin: auto 10; width:100px; height:80px">
        <span style="line-height: 80px;font-size: 28px;font-weight: bolder;color:#004199">北京元晟合智科技有限公司</span>
      </div>

      <br><br>
      <div style="float: right;position: relative;" v-if="isShow">
        <router-link to="/home">产品中心&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
        <router-link to="/about">关于我们&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
        <router-link to="/contact">联系我们&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
        <router-link to="/coop">校企合作&nbsp;&nbsp;&nbsp;&nbsp;</router-link>
        <router-link to="/news">新闻中心&nbsp;</router-link>
      </div>
    </div>
    <router-view />
    <LiveChat></LiveChat>
    <Footer v-if="isShow"></Footer>
  </div>
</template>

<script>
import Footer from './views/Footer.vue'
import Common from '@/components/Common.vue'
import LiveChat from '@/views/LiveChat.vue'

export default {
  name: 'app',
  data: function () {
    return {
      isShow1: Common.isShow
    }
  },
  // beforeCreate () {
  //   Common.isShow = true
  //   console.log(Common.isShow)
  // },
  created () {
    this.isShow = true
  },
  components: {
    Footer,
    LiveChat
  },
  watch: {
    $route (e) {
      if (e.name === 'Detail') {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  }
}
</script>

<style scoped>
  .mui-active {
    text-decoraction: none;
    font-weight: bold;
    color: #f00;
  }

  #app {
    font-family: Microsoft Yahei, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: lighter;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
    width: 1200px;
    margin: 20px auto;
    height: 80px;
    font-family: "microsoft yahei";
  }

  #nav a {
    text-align: center;
    font-weight: normal;
    color: #2c3e50;
    font-size: 18px;
    text-decoration: none;
  }

  #nav a.router-link-exact-active {
    color: #f00;
  }
</style>
