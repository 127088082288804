<template>
  <div></div>
</template>

<script>
const isShow = false
// function add ( a, b) {
//   return a + b
// }
export default {
  isShow
}
</script>

<style>
</style>
