<template>
  <div>
    <div class="right_bottom">
      <el-tooltip class="item" effect="dark" content="联系我们" placement="left-start">
            <img src="../assets/images/en_3.png" alt="联系我们" @click="open"/>
          </el-tooltip>
    </div>
    <el-dialog title="联系我们" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="留言">
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: 'LiveChat',
  data () {
    return {
      dialogFormVisible: false,
      form: {
        name: '',
        phone: '',
        content: ''
      }
    }
  },
  methods: {
    open () {
      this.dialogFormVisible = true
    },
    submit () {
      console.log(this.form.name)
      this.dialogFormVisible = false
    }
  }
}
</script>

<style scoped>
  .right_bottom{
    position: fixed;
    right: 20px;
    bottom: 50px;
    border: 1px solid #666;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 5px #ccc;
  }
  .right_bottom>img{
    width: 60px;
    height: 60px;
  }
</style>
