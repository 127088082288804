<template>
  <div class="home">
    <div class="main_box1">
      <div class="main_box1_cont">
        <p>产品服务</p>
        <span>
          提供医学虚拟仿真整体解决方案，搭建具有开放<br>性、扩展性、特色性、兼容性、泛开性和前瞻性<br>的校级和院级虚拟仿真实验教学平台，深入推进<br>信息技术与高等教育实验教学的深度融合。
        </span>
        <a href="contact_us.html">联系我们...</a>
        <img class="box1_cont_img" alt="" src="../assets/images/product/cont1_img.png">
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="main_box4">
      <!-- 视频演示 -->
      <!-- <div class="video_box" onclick="openLogin()"></div> -->
      <!-- <div class="box4_cont1">
        <div class="box4_cont_child">
          <p>护理医学</p>
          <a href="hlyx/hlxnfzsy_detail.html">护理虚拟仿真实验系统</a>
          <a href="hlyx/jjhl_detail.html">急救护理虚拟训练考核系统</a>
          <a class="arraws">健康评估系统</a>
        </div>
      </div> -->
      <div v-for="(item, index) in prodList" v-bind:key="index">
      <div v-bind:style="{'background-image': 'url(' + item.img + ')', 'height':'249px'}">
        <div class="box4_cont_child">
          <p>{{item.name}}</p>
          <a href="#" v-for="(a, i) in item.child" v-bind:key="i" @click="toDetail(a)">{{a.name}}</a>
        </div>
      </div>
      </div>
      <!-- <div class="box4_cont3">
        <div class="box4_cont_child">
          <p>基础医学</p>
          <a href="jcyx/jnxxnsyxt_detail.html">机能学虚拟实验系统</a>
          <a href="#" @click="toDetail(1)">解剖学虚拟实验教学系统</a>
          <a href="jcyx/xtxxnfzsyxt_detail.html">形态学虚拟实验系统</a>
          <a href="jcyx/shyfzswx_detail.html">生化与分子生物学虚拟实验系统</a>
        </div>
      </div>
      <div class="box4_cont4">
        <div class="box4_cont_child">
          <p>检验医学</p>
          <a class="arraws">卫生检验虚拟仿真实验系统</a>
          <a href="jyyx/yxjyxnsyxt_detail.html">医学检验虚拟仿真实验系统</a>
          <a href="jyyx/dxyqxnxt_detail.html">大型仪器系统</a>
        </div>
      </div>
      <div class="box4_cont5">
        <div class="box4_cont_child">
          <p style="margin-bottom: 10px;margin-top: 33px;">影像医学</p>
          <a href="yxyx/yxjcjs_detail.html">影像检查技术虚拟仿真系统</a>
          <a href="yxyx/yxzdxnfzxt_detail.html">影像诊断系统</a>
          <a href="yxyx/yxsb_detail.html">影像设备系统</a>
          <a class="arraws">成像原理系统</a>
          <a class="arraws">电工电子训练考核系统</a>
        </div>
      </div>
      <div class="box4_cont6">
        <div class="box4_cont_child">
          <p>中医中药</p>
          <a href="zyzy/zyxnxt_detail.html">中药虚拟系统</a>
          <a class="arraws">中医护理系统</a>
        </div>
      </div>
      <div class="box4_cont7">
        <div class="box4_cont_child">
          <p>口腔医学</p>
          <a href="">口腔数字标本系统</a>
          <a class="arraws">口腔临床技能系统</a>
        </div>
      </div>-->
    </div>
    <div class="main_box2">
      <div class="main_box2_cont">
        <p>产品特点</p>
        <div class="box2_lside">
          <div class="box2_child box2_child_border">
            <img alt="" src="../assets/images/product/cont2_ico2.png">
            <p>
              过程仿真
              <span>
                现在已形成了基础医学、临床医学 、护理学、中医中药、临床检验学和医学影像等多学科虚拟仿真平台。
              </span>
            </p>
          </div>
          <div class="box2_child">
            <img alt="" src="../assets/images/product/cont2_ico3.png">
            <p>
              实战交互
              <span>
                采用逻辑思维合理、多类型的人机交互方式呈现各个学科的仿真实验。
              </span>
            </p>
          </div>
        </div>
        <div class="box2_rside">
          <div class="box2_child box2_child_border">
            <img alt="" src="../assets/images/product/cont2_ico1.png">
            <p>
              虚拟3D
              <span>
                系统采用先进的计算机虚拟仿真技术，实现虚拟3D场景应用，高精度还原真实操作。
              </span>
            </p>
          </div>
          <div class="box2_child">
            <img alt="" src="../assets/images/product/cont2_ico4.png">
            <p>
              专家指导
              <span>
                产品是在各学科、行业领军人物及教学与临床一线专家的多年悉心指导下的“匠心之作”。
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三部分 -->
    <div class="main_box3">
      <div class="main_box3_cont">
        <p>产品优势</p>
        <div class="box3_cont">
          <p>
            <span class="yuan_span">●</span>
            <span class="span_bt">&nbsp;开放性&nbsp;&nbsp;</span>
            高仿真场景和虚拟实验，线上学习线下交流，采用人机交互，增加实战性；
          </p>
        </div>
        <div class="box3_cont">
          <p>
            <span class="yuan_span">●</span>
            <span class="span_bt">&nbsp;扩展性&nbsp;&nbsp;</span>
            采用B/S架构，网络环境支持校园网、互联网，不限制学生登录端口数量；
          </p>
        </div>
        <div class="box3_cont">
          <p>
            <span class="yuan_span">●</span>
            <span class="span_bt">&nbsp;特色性&nbsp;&nbsp;</span>
            学校可根据自身教学实际内容，量身定制功能框架和虚拟仿真实验项目；
          </p>
        </div>
        <div class="box3_cont">
          <p>
            <span class="yuan_span">●</span>
            <span class="span_bt">&nbsp;兼容性&nbsp;&nbsp;</span>
            与其他教务和实验室管理软件不冲突，可相互挂靠，兼容并行；
          </p>
        </div>
        <div class="box3_cont">
          <p>
            <span class="yuan_span">●</span>
            <span class="span_bt">&nbsp;泛开性 &nbsp;&nbsp;</span>
            虚拟仿真实验、题库、视频等，紧密贴近教学大纲和临床实践；
          </p>
        </div>
        <div class="box3_cont">
          <p>
            <span class="yuan_span">●</span>
            <span class="span_bt">&nbsp;前瞻性&nbsp;&nbsp;</span>
            与当前教育部虚拟仿真示范项目建设思路相吻合，紧贴国家建设方针；
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      toUrl: '',
      prodList: []
    }
  },
  created () {
    this.load()
  },
  components: {},
  methods: {
    toDetail (e) {
      this.toUrl = this.$router.resolve({ path: '/detail', query: { id: e.id, name: e.name } })
      window.open(this.toUrl.href, '_blank')
      // this.$router.push({
      //   path: '/detail',
      //   query: {
      //     id: e
      //   }
      // }).catch(err => { console.log(err) })
    },
    load () {
      this.prodList.push(
        {
          id: 1,
          name: '临床医学',
          img: require('../assets/images/product/cont4_img1.jpg'),
          child: [
            { id: 1, name: '临床诊断思维虚拟训练考核系统' },
            { id: 2, name: '临床助理职业医师考核系统' },
            { id: 3, name: '临床技能虚拟仿真实验系统' }
          ]
        },
        {
          id: 2,
          name: '基础医学',
          img: require('../assets/images/product/cont4_img2.jpg'),
          child: [
            { id: 4, name: '机能学虚拟实验系统' },
            { id: 5, name: '解剖学虚拟实验教学系统' },
            { id: 6, name: '形态学虚拟实验系统' },
            { id: 7, name: '生化与分子生物学虚拟实验系统' }
          ]
        }
      )
    }
  }
}
</script>
<style scoped>
  .main_box1 {
    width: 100%;
    height: 400px;
    background: url(../assets/images/product/cont_bg1.png) no-repeat;
    background-size: 100% 100%;
  }

  .main_box1_cont {
    width: 1000px;
    margin: 20px 10px;
    position: relative;
  }

  .main_box1_cont p {
    font-size: 40px;
    color: #ffffff;
    font-family: "SimHei";
    cursor: default;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .main_box1_cont span {
    width: 386px;
    font-size: 16px;
    color: #ffffff;
    display: block;
    cursor: default;
    line-height: 25px;
  }

  .main_box1_cont a {
    font-size: 16px;
    color: #1fd1d7;
    display: block;
    margin-top: 70px;
  }

  .box1_cont_img {
    position: absolute;
    top: 30px;
    right: 0px;
  }

  .box1_cont_img_active {
    position: absolute;
    top: 82px;
    right: 0px;
  }

  .video_box {
    width: 1000px !important;
    height: 134px !important;
    background: url(../assets/images/product/video_box_bg.png) no-repeat;
  }

  .video_box:HOVER {
    width: 1000px !important;
    height: 134px !important;
    background: url(../assets/images/product/video_box_bg_hover.png) no-repeat;
  }

  .main_box2 {
    width: 100%;
    height: 440px;
    background: #f2f2f2;
  }

  .main_box2_cont {
    width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .box2_lside {
    float: left;
  }

  .main_box2_cont>p {
    font-size: 40px;
    color: #333333;
    font-family: "SimHei";
    padding-top: 60px;
    padding-bottom: 10px;
    cursor: default;
  }

  .box2_child {
    width: 470px;
    height: 105px;
    cursor: default;
    margin-top: 30px;
  }

  .box2_child_border {
    border-bottom: 2px dashed #f5f5f5;
  }

  .box2_child img {
    float: left;
  }

  .box2_child p {
    width: 357px;
    float: right;
    font-size: 20px;
    color: #414141;
    font-family: "SimHei";
    padding-top: 2px;
  }

  .box2_child p span {
    font-size: 15px;
    color: #414141;
    display: block;
    margin-top: 6px;
    line-height: 23px;
  }

  .box2_rside {
    width: 470px;
    border-left: 2px dashed #f5f5f5;
    float: right;
    padding-left: 20px;
  }

  .main_box3_cont {
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 80px;
  }

  .main_box3_cont>p {
    font-size: 40px;
    color: #333333;
    font-family: "SimHei";
    padding-top: 60px;
    cursor: default;
  }

  .box3_cont {
    margin-top: 35px;
    cursor: default;
  }

  .box3_cont p {
    font-size: 16px;
  }

  .yuan_span {
    font-size: 14px;
    font-family: "SimHei";
  }

  .span_bt {
    font-size: 18px;
    font-family: "SimHei";
  }

  .main_box4 {
    width: 1000px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 70px;
  }

  .main_box4>div {
    width: 1000px;
    height: 250px;
    margin-top: 25px;
    background-size: 100% 100%;
  }

  .box4_cont_child {
    width: 390px;
    float: right;
  }

  .box4_cont_child p {
    font-size: 30px;
    color: white;
    font-family: "SimHei";
    margin-top: 40px;
    margin-left: 37px;
    margin-bottom: 20px;
    cursor: default;
  }

  .box4_cont_child a {
    display: block;
    font-size: 18px;
    color: white;
    font-family: "SimHei";
    margin-left: 70px;
    line-height: 32px;
  }

  .box4_cont_child a:HOVER {
    color: #57f7f9;
  }

  .box4_cont1 {
    height: 250px;
    background: url(../assets/images/product/cont4_img1.jpg) no-repeat;
  }

  .box4_cont1:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img1_hover.jpg) no-repeat;
  }

  .box4_cont2 {
    height: 250px;
    background: url(../assets/images/product/cont4_img2.jpg) no-repeat;
  }

  .box4_cont2:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img2_hover.jpg) no-repeat;
  }

  .box4_cont3 {
    height: 249px;
    background: url(../assets/images/product/cont4_img3.jpg) no-repeat;
  }

  .box4_cont3:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img3_hover.jpg) no-repeat;
  }

  .box4_cont4 {
    height: 249px;
    background: url(../assets/images/product/cont4_img4.jpg) no-repeat;
  }

  .box4_cont4:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img4_hover.jpg) no-repeat;
  }

  .box4_cont5 {
    height: 250px !important;
    background: url(../assets/images/product/cont4_img5.jpg) no-repeat;
  }

  .box4_cont5:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img5_hover.jpg) no-repeat;
  }

  .box4_cont6 {
    height: 249px;
    background: url(../assets/images/product/cont4_img6.jpg) no-repeat;
  }

  .box4_cont6:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img6_hover.jpg) no-repeat;
  }

  .box4_cont7 {
    height: 249px;
    background: url(../assets/images/product/cont4_img7.jpg) no-repeat;
  }

  .box4_cont7:HOVER {
    height: 250px;
    background: url(../assets/images/product/cont4_img7.jpg) no-repeat;
  }
</style>
