import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Home
},
{
  path: '/home',
  name: 'Home',
  component: Home
},
{
  path: '/about',
  name: 'About',
  component: () => import('../views/About.vue')
},
{
  path: '/about',
  name: 'About',
  component: () => import('../views/About.vue')
},
{
  path: '/contact',
  name: 'Contact',
  component: () => import('../views/Contact.vue')
},
{
  path: '/coop',
  name: 'Coop',
  component: () => import('../views/Cooperation.vue')
},
{
  path: '/news',
  name: 'News',
  component: () => import('../views/News.vue')
},
{
  path: '/detail',
  name: 'Detail',
  meta: {
    title: ''
  },
  component: () => import('../views/Detail.vue')
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
